import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../../container/SearchResults/logic'

const CREATE_SITE = 'CREATE_SITE'
const CREATE_SITE_SUCCESS = 'CREATE_SITE_SUCCESS'
const CREATE_SITE_RESET = 'CREATE_SITE_RESET'
const CREATE_SITE_FAILURE = 'CREATE_SITE_FAILURE'

const ADD_PARENT = 'ADD_PARENT'
const ADD_PARENT_SUCCESS = 'ADD_PARENT_SUCCESS'
const ADD_PARENT_RESET = 'ADD_PARENT_RESET'
const ADD_PARENT_FAILURE = 'ADD_PARENT_FAILURE'

const GET_SITE_QUES = 'GET_SITE_QUES'
const GET_SITE_QUES_SUCCESS = 'GET_SITE_QUES_SUCCESS'
const GET_SITE_QUES_FAILURE = 'GET_SITE_QUES_FAILURE'
const GET_SITE_QUES_RESET = 'GET_SITE_QUES_RESET'

const UPDATE_SITE = 'UPDATE_SITE'
const UPDATE_SITE_SUCCESS = 'UPDATE_SITE_SUCCESS'
const UPDATE_SITE_RESET = 'UPDATE_SITE_RESET'
const UPDATE_SITE_FAILURE = 'UPDATE_SITE_FAILURE'

const UPDATE_PARENT = 'UPDATE_PARENT'
const UPDATE_PARENT_SUCCESS = 'UPDATE_PARENT_SUCCESS'
const UPDATE_PARENT_RESET = 'UPDATE_PARENT_RESET'
const UPDATE_PARENT_FAILURE = 'UPDATE_PARENT_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const createSiteAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: CREATE_SITE,
      url: `${process.env.apiUrl}v0/search/createsite?${stringify(payload.params)}`,
      method: 'POST',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: 'Center sent for approval',
    hideNotification: false,
    customErrMessage: 'Warning : Center already exists with this name',
  })
}

export const addSiteResetAction = () => ({
  type: CREATE_SITE_RESET,
})

export function createSiteReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case CREATE_SITE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CREATE_SITE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case CREATE_SITE_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case CREATE_SITE_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const updateSiteAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: UPDATE_SITE,
      url: `${process.env.apiUrl}v0/search/updateSite?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: 'Site updated successfully',
    hideNotification: false,
    customErrMessage: 'Warning : SITE already exists with this name',
  })
}

export const updateSiteResetAction = () => ({
  type: UPDATE_SITE_RESET,
})

export function updateSiteReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPDATE_SITE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_SITE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case UPDATE_SITE_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case UPDATE_SITE_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getSiteQuesDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_SITE_QUES,
      url: `${process.env.apiUrl}v0/search/siteQuestions`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getSiteQuesDataResetAction = () => ({
  type: GET_SITE_QUES_RESET,
})

export function getSiteQuesDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_SITE_QUES: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SITE_QUES_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_SITE_QUES_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_SITE_QUES_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const addParentAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: ADD_PARENT,
      url: `${process.env.apiUrl}v0/institution/createParentsite?${stringify(payload.params)}`,
      method: 'POST',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: 'Parent Institution sent for approval',
    hideNotification: false,
    customErrMessage: 'Warning : Parent Institution already exists with this name',
  })
}

export const addParentResetAction = () => ({
  type: ADD_PARENT_RESET,
})

export function addParentReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case ADD_PARENT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case ADD_PARENT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case ADD_PARENT_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case ADD_PARENT_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const updateParentAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: UPDATE_PARENT,
      url: `${process.env.apiUrl}v0/institution/updateParentSite?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: 'Parent Institution updates successfully',
    hideNotification: false,
    customErrMessage: 'Warning : Parent Institution already exists with this name',
  })
}

export const updateParentResetAction = () => ({
  type: UPDATE_PARENT_RESET,
})

export function updateParentReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPDATE_PARENT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_PARENT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case UPDATE_PARENT_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case UPDATE_PARENT_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
